import * as Sentry from '@sentry/sveltekit';
import logger from '$lib/logger';
import type { HandleClientError } from '@sveltejs/kit';

let handleError: HandleClientError = async ({
  error,
  event,
  status,
  message,
}) => {
  logger.error({
    err: error,
    event,
    status,
    message,
  });
};

if (import.meta.env.VITE_DISABLE_SENTRY !== 'true') {
  Sentry.init({
    dsn: 'https://38ed711d14d299032cedc7aaf0722f9f@o133726.ingest.us.sentry.io/4506854323650560',
    tracesSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    initialScope(scope) {
      scope.setContext('package', {
        name: '@rovedr/server',
        component: 'client',
      });
      return scope;
    },
  });

  const clientErrorHandler = ({ error, event }) => {
    logger.error({
      err: error,
      event,
    });
  };

  handleError = Sentry.handleErrorWithSentry(clientErrorHandler);
}

export { handleError };
